<template>
<div>
  <template>
    <el-table
      :data="allMachine"
      stripe
      style="width: 100%">
      <el-table-column
        prop="createDate"
        label="日期"
        width="180">
      </el-table-column>
      <el-table-column
        prop="electricTotal"
        label="用水量"
        width="180">
      </el-table-column>
      <el-table-column
        prop="electricPower"
        label="用电量">
      </el-table-column>
    </el-table>
  </template>
</div>
</template>

<script>
import clientUtils from "../../js/clientUtils";

export default {
  name: "biao",
  data(){
    return{
allMachine:[]
    }
  },
  mounted() {
    const that = this
    clientUtils.get("/system/networkData/findAll?current=1&size=15&name=刘山中心站1(刘山大地)",function (res) {
      if(res.data!=null){
        that.allMachine = res.data.data.records
        that.pagetotal=parseInt(res.data.data.total);
        console.log(that.allMachine)
      }
    })
  }
}
</script>

<style scoped>

</style>
